<template>
  <div style="margin: 0 auto; width: 100%;">
    <h3>Hashing:</h3>
    <div v-for="link in hashLinks">
      <a class="text hashing" :href="link" v-text="link"></a>
    </div>
    <hr />
    <h3>Encoding:</h3>
    <div v-for="link in encLinks">
      <a class="text encoding" :href="link" v-text="link"></a>
    </div>
  </div>
</template>


<script>
export default {
  name: "usage",
  data: function() {
    return {
      hashLinks: [
        "?text=Enigio&hash=43e741d890a32e8076d41124c1f34b7cd5d0399c",
        "?text=Enigio&hash=43e741d890a32e8076d41124c1f34b7cd5d0399d",
        "?text=Enigio&hash=6b7e021b66fc09a7acce79822c9f6ba72bd3d3828704ef53c1b848b988d38da7",
        "?text=Enigio&hash=e48c2dd460b963617cd98c6049b17b69a85cb5b7aa59b9fdcb0400e680f1df573af7b08a8a42b80dd4342713b206db75",
        "?text=hej%0Asvejs&hash=c2d6a67d38979e1e7c65e3a3112a34dc4e1bc48a"
      ],
      encLinks: [
        "?hex=74657374&expected=3yZe7d",
        "?hex=746573745f6661696c&expected=2Uw1bpuyqR9M",
        "?base58check=13FhCLBTAMvLKYPu4F&expected=7768657465766572",
        "?base58check=13FhCLBTAMvLKYPu4F&expected=7768657465766573",
        "?base58=LyQW9pYX9fw&expected=7768657465766572",
        "?text=hej&expected=14xTUgEPx4L",
        "?hex=6f707074707469696969&expected=1hyCC9K32wzD2izxbsQb",
        "?hex=38393335373832333635383236393233373638393938373839&expected=113Z3CDLm821jFwkDeWfuVx2uwNUKTU9uQ9vTRuhyF"
      ]
    };
  }
};
</script>

<style scoped>
.text {
  font-family: monospace;
  word-break: break-all;
  padding: 0 10px;
}
</style>

