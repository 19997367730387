<template>
  <label class="fileInput" :style="$parent.$data.isDone?'color:black':'color:lightgray'">
    <input type="file" required @change="doChange" :disabled="!$parent.$data.isDone" />
    <span>Select a file</span>
  </label>
</template>

<script>
export default {
  name: "fileInput",
  methods: {
    doChange: function(ev) {
      this.$emit("change", ev);
    }
  }
}
</script>

<style scoped>
label.fileInput input[type="file"] {
  display: none;
}

.fileInput {
  letter-spacing: 0.3rem;
  border-radius: 6px;
  padding: 15px 0;
  background: #03af84;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  max-width: 756px;
  width: 80%;
  transition: background-color 0.25s ease-out;
  transition-duration: 0.5s;
  transition-property: background-color;
  text-transform: uppercase;
}

.fileInput:hover {
  background: rgba(0, 204, 168, 1);
}

.fileInput span {
  color: #fff;
  font-size: 1.2rem;
}
</style>

