<template>
  <div style="position: absolute; width: 98%; height: 98%">
    <img id="logo" src="./img/logo-bighash-white-green.svg" alt="bighash-logo" @click="reset()" />
    <div id="menu">
      <a
        href="#"
        ref="hashing"
        @click="doHashing()"
        :class="{ active: showHashing }"
      >Hashing</a>
      <a
        href="#"
        ref="encoding"
        @click="doEncoding()"
        :class="{ active: showEncoding }"
      >Encoding</a>
      <a href="#" ref="usage" @click="usage()" :class="{ active: showUsage }">Usage</a>
    </div>
    <div id="enigio">
      <a href="https://enigio.com" target="_blank">
        <img id="enigio-logo" src="./img/logo-enigio-white.svg" />
      </a>
    </div>
    <hr />
    <div style="margin-top: 6%; z-index: -100;">
      <usage v-if="showUsage"/>
      <encodings :q="this.q" v-show="showEncoding"></encodings>
      <hashes :q="this.q" v-show="showHashing"></hashes>
    </div>
  </div>
</template>

<script>
import Usage from "./usage";
import Encodings from "./encodings";
import Hashes from "./hashes";

export default {
  name: "BigHash",
  components: { Usage, Hashes, Encodings },
  data: function() {
    let q = {};
    try {
      location.href
        .split("?")[1]
        .split("&")
        .forEach(function(i) {
          q[i.split("=")[0]] = decodeURIComponent(i.split("=")[1]);
        });
    } catch (e) {
      q = false;
    }
    return {
      q: q,
      showUsage: false,
      showEncoding: false,
      showHashing: false,
    };
  },

  mounted() {
    if (!this.q || this.q.hash) {
      this.doHashing();
    } else {
      this.doEncoding();
    }
    document.onkeydown = this.escape;
  },

  methods: {
    escape: function(e) {
      if (e.key === "Escape") {
        setTimeout(function() {
          window.location.href = "/";
        }, 5);
      }
    },
    reset: function() {
      window.location.href = "/";
    },
    usage: function() {
      this.showUsage = true;
      this.showEncoding = false;
      this.showHashing = false;
    },
    doHashing: function() {
      this.showUsage = false;
      this.showEncoding = false;
      this.showHashing = true;
      let a = document.querySelector("textarea");
      this.$nextTick(() => a.focus());
    },
    doEncoding: function() {
      this.showUsage = false;
      this.showHashing = false;
      this.showEncoding = true;
      let a = document.querySelector("input");
      this.$nextTick(() => a.focus());
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  background-color: #03283f;
  color: white;
  margin: 15px;
  font-size: 1rem;
  font-family: "Roboto", helvetica, sans-serif;
}

tbody {
  border-radius: 5px;
  border-color: #fff;
}

h1 {
  font-weight: 900;
  font-size: 38px;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
}

#menu {
  position: absolute;
  top: 35px;
  right: 40px;
}

#menu a {
  margin: 0 20px;
  font-size: 1.4rem;
  transition: color, margin-right, font-size 0.5s ease-out;
  transition-duration: 0.5s;
  transition-property: margin, font-size, color;
}

a {
  color: white;
  text-decoration: none;
}

tfoot td {
  padding-top: 10px;
  font-size: 1.5em;
}

.active {
  color: #03af84;
  font-weight: 700;
}

#enigio {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 5;
}

#logo {
  cursor: pointer;
  width: 300px;
  transition: ;

  transition: width 0.25s ease-out;
  transition-duration: 0.5s;
  transition-property: all;
}

#enigio-logo {
  width: 175px;
}

#menu a:hover {
  color: rgba(0, 204, 168, 1);
}

@media only screen and (max-width: 700px) {
  #menu {
    right: 2px;
    top: 22px;
  }

  #menu a {
    margin: 0 0.8ch;
    font-size: 0.9rem;
    transition: margin-right, font-size 0.5s ease-out;
  }

  #logo {
    transition: width 0.25s ease-out;
    transition-duration: 0.5s;
    transition-property: width;
    width: 200px;
  }

  #enigio {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  #enigio {
    display: none;
  }
}
</style>

