import Vue from 'vue'
import BigHash from './BigHash.vue'

Vue.config.productionTip = false

new Vue({
    render: function (h) {
        return h(BigHash)
    },
}).$mount('#app')

