<template>
  <div>
    <table id="input">
      <tr>
        <td>
          <file-input v-show="hashText.length===0" @change="hashFile"></file-input>
        </td>
      </tr>
      <tr v-show="!filename">
        <td colspan="2">
          <textarea
            placeholder="Or type here."
            autofocus="autofocus"
            spellcheck="false"
            v-model="hashText"
          ></textarea>
        </td>
      </tr>
    </table>

    <div v-show="expectedHash">
      <div id="message" :class="expectedClass" v-text="expectedClass==='err'?'NO MATCH!':'MATCH!'"></div>
    </div>

    <div id="showResult">
      <table id="result" v-if="hashSize>0">
        <tbody v-if="!error">
          <tr v-if="filename">
            <td class="leftCol">Filename</td>
            <td class="midCol" v-text="filename" @click="markText($event)"></td>
          </tr>
          <tr>
            <td class="leftCol">Size</td>
            <td
              class="midCol"
              v-text="new Intl.NumberFormat('fr').format(hashSize)+' bytes'"
              @click="markText($event)"
            ></td>
          </tr>
          <tr v-if="error">
            <td class="leftCol">Error</td>
            <td v-text="error" class="midCol err" @click="markText($event)"></td>
          </tr>
          <tr v-if="expectedHash" class="mono">
            <td class="leftCol">Expected</td>
            <td class="midCol" v-text="expectedHash" :class="expectedClass" @click="markText($event)"></td>
          </tr>
          <tr v-for="(val,key) in digests" class="mono">
            <td class="leftCol" v-text="hashLabel(key)"></td>
            <td class="midCol" v-text="val" :class="val===expectedHash?'good':''" @click="markText($event)"></td>
          </tr>
          <tr v-if="filename">
            <td class="leftCol">Time</td>
            <td class="midCol" @click="markText($event)">
              <span v-if="time>0" v-text="time + ' s'" ></span>
            </td>
          </tr>
          <tr v-if="filename">
            <td class="leftCol">Speed</td>
            <td class="midCol" @click="markText($event)">
              <span v-if="time>0" v-text="speed" @click="markText($event)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FileInput from "./fileInput";

function arrayBuf2hex(buf) {
  return Array.prototype.map
    .call(new Uint8Array(buf), x => ("00" + x.toString(16)).slice(-2))
    .join("");
}

const MAX_SIZE = 1000000000;

export default {
  name: "hashes",
  props: ["q"],
  components: { FileInput },
  data: function() {
    return {
      hashText: this.q.text ? this.q.text : "",
      expectedHash: this.q.hash ? this.q.hash : false,
      filename: false,
      hashSize: "",
      time: false,
      digests: {
        sha1: "",
        sha256: "",
        sha384: ""
      },
      isDone: true,
      error: false
    };
  },
  watch: {
    hashText: function(val) {
      this.expectedHash = false;
      this.doHash();
    }
  },
  mounted() {
    if (this.hashText.length > 0) {
      this.doHash();
    }
  },
  computed: {
    expectedClass: function() {
      return Object.values(this.digests).includes(this.expectedHash)
        ? "good"
        : "err";
    },
    speed: function() {
      return this.isDone
        ? parseFloat("" + this.hashSize / this.time / 1024 / 1024).toFixed(2) +
            " MB/s"
        : " ";
    }
  },
  methods: {
    doHash: async function() {
      let buf = new TextEncoder().encode(this.hashText);
      this.digests.sha1 = arrayBuf2hex(
        await crypto.subtle.digest("SHA-1", buf)
      );
      this.digests.sha256 = arrayBuf2hex(
        await crypto.subtle.digest("SHA-256", buf)
      );
      this.digests.sha384 = arrayBuf2hex(
        await crypto.subtle.digest("SHA-384", buf)
      );
      this.hashSize = this.hashText.length;
    },
    reset() {
      this.digests.sha1 = ".".repeat(40);
      this.digests.sha256 = ".".repeat(64);
      this.digests.sha384 = ".".repeat(96);
      this.time = false;
      this.isDone = false;
      this.error = false;
    },
    hashLabel: function(h) {
      return h === "md5" ? "MD5" : "SHA-" + h.substr(3);
    },
    hashFile: function(ev) {
      const file = ev.target.files[0];
      console.log('hashFile....' + file.size);
      this.filename = file.name;
      this.hashSize = file.size;
      if (file.size > MAX_SIZE) {
        this.error = "Sorry, too big file. Max size is " + MAX_SIZE + ".";
        alert(this.error);
        window.location.href = "/";
        return;
      }
      this.reset();
      const startTime = new Date().getTime();
      const worker = new Worker("hashWorker.js");
      worker.addEventListener("message", e => {
        console.log("result:", e.data);
        this.time = (new Date().getTime() - startTime) / 1000;
        if (e.data === "end") {
          worker.terminate();
          this.isDone = true;
        } else {
          this.digests[e.data.algo] = e.data.hash;
        }
      });
      worker.postMessage({ file });
      this.time = (new Date().getTime() - startTime) / 1000;
    },

    markText: function(e) {
      let range = document.createRange();
      range.selectNodeContents(e.target);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

#input {
  width: 100%;
  text-align: center;
}

#message {
  text-align: center;
  font-size: 4ch;
  padding: 10px;
  border: 0px;
}


.good {
  color: #03af84;
}

.err {
  color: #dd3333;
}

textarea {
  max-width: 750px;
  width: 80%;
  height: 250px;
  outline: none;
  opacity: 0.9;
  border-radius: 5px;
  border-color: #fff;
  font-size: 1.4ch;
  font-family: monospace;
  resize: none;
  margin-top: 15px;
  text-align: left;
  transition: opacity 0.25s ease-out;
  transition-property: opacity;
}

textarea::placeholder {
  font-size: 2ch;
  opacity: 1;
  font-family: "Roboto", helvetica, sans-serif;
}

textarea:focus {
  opacity: 1;
  transition: opacity 0.25s ease-out;
  transition-duration: 0.5s;
  transition-property: opacity;
}

#result {
  margin: 15px auto;
  font-family: monospace;
  background: #385366;
  border: 1px solid white;
  border-radius: 5px;
  border-collapse: collapse;
  max-width: 760px;
  width: 80%;
  border-style: hidden;
  box-shadow: 0 0 0 1px;
}

#result td {
    border: 1px solid #fff;
}

#result tbody tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}
#result tbody tr:first-child td:first-child {
    border-radius: 5px 0 0 0;
}

.leftCol {
  font-family: "Roboto", helvetica, sans-serif;
  text-align: right;
  padding-right: 15px;
  padding: 15px;
  background: #03af84;
  width: 70px;
  /* display: table-cell; */
}
.midCol {
  word-break: break-all;
  padding: 0 10px;
}

</style>